import crossfilter, { Bisection, NaturallyOrderedValue } from 'crossfilter2'
import SemVer from 'semver/classes/semver'
import coerce from 'semver/functions/coerce'
import compare from 'semver/functions/compare'
import rcompare from 'semver/functions/rcompare'

import { Reducer, ReducerConstructor, SerializedReducer } from '.'

export abstract class ValueListReducer implements Reducer {
  metric?: string
  valueList: Map<Record<string, any>, NaturallyOrderedValue[]>
  bisect: Bisection<NaturallyOrderedValue>

  constructor (metric?: string) {
    this.metric = metric
    this.bisect = crossfilter.bisect.by((d: NaturallyOrderedValue) => d).left
    this.valueList = new Map()
  }

  key (key: string): string {
    return this.metric || key
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    let valueList = this.valueList.get(p)
    if (valueList === undefined) {
      valueList = []
      this.valueList.set(p, valueList)
    }
    const n = v[this.key(key)]
    const i = this.bisect(valueList, n, 0, valueList.length)
    valueList.splice(i, 0, n)
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const valueList = this.valueList.get(p)!
    const n = v[this.key(key)]
    const i = this.bisect(valueList, n, 0, valueList.length)
    valueList.splice(i, 1)
  }

  init (p: Record<string, any>, key: string): void {
    p[key] = NaN
  }
}

export class MinReducer extends ValueListReducer {
  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    super.add(p, key, v)
    const valueList = this.valueList.get(p)!
    p[key] = valueList.length ? valueList[0] : NaN
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    super.remove(p, key, v)
    const valueList = this.valueList.get(p)!
    p[key] = valueList.length ? valueList[0] : NaN
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined) {
      throw new Error('Invalid SerializedReducer (MinReducer): missing parameters')
    }
    return MinReducerConstructor(m.params.metric)
  }
}

/**
 * @param metric the name of the metric to find the min off
 * @returns MinReducer constructor using this metric
 */
export function MinReducerConstructor (metric: string): ReducerConstructor {
  return class extends MinReducer {
    constructor () { super(metric) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'MinReducer',
        params: {
          metric
        }
      }
    }
  }
}

export class MaxReducer extends ValueListReducer {
  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    super.add(p, key, v)
    const valueList = this.valueList.get(p)!
    p[key] = valueList.length ? valueList[valueList.length - 1] : NaN
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    super.remove(p, key, v)
    const valueList = this.valueList.get(p)!
    p[key] = valueList.length ? valueList[valueList.length - 1] : NaN
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined) {
      throw new Error('Invalid SerializedReducer (MaxReducer): missing parameters')
    }
    return MaxReducerConstructor(m.params.metric)
  }
}

/**
 * @param metric the name of the metric to find the max off
 * @returns MaxReducer constructor using this metric
 */
export function MaxReducerConstructor (metric: string): ReducerConstructor {
  return class extends MaxReducer {
    constructor () { super(metric) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'MaxReducer',
        params: {
          metric
        }
      }
    }
  }
}

type ExtendedSemVer = SemVer & { originalValue?: crossfilter.NaturallyOrderedValue }

export function processVersion (
  version: crossfilter.NaturallyOrderedValue
): ExtendedSemVer | undefined {
  const processedVersion: ExtendedSemVer | undefined = coerce(String(version), { includePrerelease: true, loose: true }) || undefined
  if (!processedVersion) {
    return
  }
  processedVersion.originalValue = version
  return processedVersion
}

export class MaxVersionReducer implements Reducer {
  metric?: string
  valueList: Map<Record<string, any>, ExtendedSemVer[]>

  constructor (metric?: string) {
    this.metric = metric
    this.valueList = new Map()
  }

  key (key: string): string {
    return this.metric || key
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    let valueList = this.valueList.get(p) as ExtendedSemVer[]
    if (valueList === undefined) {
      valueList = []
      this.valueList.set(p, valueList)
    }
    const n = processVersion(v[this.key(key)])
    if (!n) {
      return
    }
    valueList.push(n)
    valueList.sort(rcompare as ((a: SemVer, b: SemVer) => number))
    p[key] = valueList.length ? valueList[0].originalValue : ''
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const valueList = this.valueList.get(p)!
    const n = processVersion(v[this.key(key)])
    if (!n) {
      return
    }
    const i = valueList.indexOf(n)
    if (i !== -1) {
      valueList.splice(i, 1)
      p[key] = valueList.length ? valueList[0].originalValue : ''
    }
  }

  init (p: Record<string, any>, key: string): void {
    p[key] = ''
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined) {
      throw new Error('Invalid SerializedReducer (MaxVersionReducer): missing parameters')
    }
    return MaxVersionReducerConstructor(m.params.metric)
  }
}

export function MaxVersionReducerConstructor (metric: string): ReducerConstructor {
  return class extends MaxVersionReducer {
    constructor () { super(metric) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'MaxVersionReducer',
        params: {
          metric
        }
      }
    }
  }
}

export class MinVersionReducer implements Reducer {
  metric?: string
  valueList: Map<Record<string, any>, ExtendedSemVer[]>

  constructor (metric?: string) {
    this.metric = metric
    this.valueList = new Map()
  }

  key (key: string): string {
    return this.metric || key
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    let valueList = this.valueList.get(p) as ExtendedSemVer[]
    if (valueList === undefined) {
      valueList = []
      this.valueList.set(p, valueList)
    }
    const n = processVersion(v[this.key(key)])
    if (!n) {
      return
    }
    valueList.push(n)
    valueList.sort(compare as ((a: SemVer, b: SemVer) => number))
    p[key] = valueList.length ? (valueList[0].originalValue) : ''
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const valueList = this.valueList.get(p)!
    const n = processVersion(v[this.key(key)])
    if (!n) {
      return
    }
    const i = valueList.indexOf(n)
    if (i !== -1) {
      valueList.splice(i, 1)
      p[key] = valueList.length ? valueList[0].originalValue : ''
    }
  }

  init (p: Record<string, any>, key: string): void {
    p[key] = ''
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined) {
      throw new Error('Invalid SerializedReducer (MinVersionReducer): missing parameters')
    }
    return MinVersionReducerConstructor(m.params.metric)
  }
}

export function MinVersionReducerConstructor (metric: string): ReducerConstructor {
  return class extends MinVersionReducer {
    constructor () { super(metric) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'MinVersionReducer',
        params: {
          metric
        }
      }
    }
  }
}
