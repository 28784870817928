import { SerializedReducer, ReducerConstructor, FormulaReducer, SumReducer, AverageReducer, WeightedAverageReducer, StringReducer, DateReducer, RateReducer, UniqueReducer, MinReducer, MaxReducer, MinVersionReducer, MaxVersionReducer } from '.'

export const REDUCER_CONSTRUCTORS: Record<string, any> = {
  FormulaReducer,
  SumReducer,
  AverageReducer,
  WeightedAverageReducer,
  StringReducer,
  DateReducer,
  RateReducer,
  UniqueReducer,
  MinReducer,
  MaxReducer,
  MinVersionReducer,
  MaxVersionReducer
}

export function deserializeReducer (def: SerializedReducer): ReducerConstructor {
  const reducer = REDUCER_CONSTRUCTORS[def.constructor]
  if (reducer) {
    return reducer.deserialize(def)
  } else {
    throw new Error(`Invalid SerializedWidgetDefinition: metric constructor "${def.constructor}" doesn't exist`)
  }
}
